import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import './style.scss';
import { DEFAULT_TEXT_COLOR_BLACK } from 'settings/constants/dashboard';

const CardContentItem = ({ title, info, className, valueColor }) => (
  <Grid container className={`content--item ${className}`}>
    <Grid item xs={6}>
      <Typography className="item--left">{title}</Typography>
    </Grid>

    <Grid item xs={6}>
      <Typography className="item--right" style={{ color: valueColor }}>
        {info}
      </Typography>
    </Grid>
  </Grid>
);

CardContentItem.defaultProps = { info: '', title: '', className: '', valueColor: DEFAULT_TEXT_COLOR_BLACK };

CardContentItem.propTypes = { title: PropTypes.node, info: PropTypes.node, className: PropTypes.string, valueColor: PropTypes.string };

export default CardContentItem;
