export const transfer = {
  or: 'OR',
  label: 'Transfer',
  from: 'Transfer from',
  to: 'Transfer to',
  message: 'Message',
  myFavorites: 'My Favorites',
  transactionFee: 'Transaction Fee',
  transactionAmount: 'Transaction Amount',
  transactionCharges: 'Transaction Charges',
  availableBalance: 'Available Balance',
  accountNumber: 'Account Number',
  amount: 'Amount',
  effectiveDate: 'Effective Date',
  setRecurring: 'Set Recurring',
  frequency: 'Frequency',
  monthly: 'Monthly',
  weekly: 'Weekly',
  start: 'Start',
  end: 'End',
  selectDate: 'Select date',
  debitDate: 'Debit Date',
  debitDay: 'Debit Day',
  note: 'Note:',
  notes: 'Notes:',
  selectBank: 'Select bank',
  identityNumber: 'ID Number',
  sourceOfFund: 'Source Of Fund',
  selectCurrency: 'Select Currency',
  transactionPurpose: 'Transaction Purpose',
  beneficiary: {
    type: 'Beneficiary Type',
    bank: 'Beneficiary Bank',
    name: 'Beneficiary Name',
    info: 'Beneficiary Information',
    email: 'Beneficiary Email',
    address: 'Beneficiary Address',
    message: 'Message (Optional)',
    category: 'Beneficiary Category',
    citizenship: 'Beneficiary Citizenship',
    bankAddress: 'Beneficiary Bank Address',
    bankCountry: 'Beneficiary Bank Country',
    swiftCode: 'Beneficiary Bank SWIFT Code',
    emailOptional: 'Beneficiary Email (Optional)',
    citizenStatus: 'Beneficiary Citizen Status',
  },
  localDebitedAmount: 'Total Amount to be Debited in IDR',
  recurringMonthly: 'Recurring Transfer - Monthly',
  recurringWeekly: 'Recurring Transfer - Weekly',
  startDate: 'Start Date',
  endDate: 'End Date',
  monetaryInfo: 'Monetary Information',
  totalDebit: 'Total Amount to be Debited',
  equivalentDebit: 'IDR Equivalent Debited',
  exchangeRate: 'Exchange Rate',
  referenceNumber: 'Reference Number',
  foreignExchange: 'Foreign Exchange',
  foreignExchangeRate: 'Foreign Exchange Rate',
  ownAccount: {
    label: 'Own Accounts',
    title: 'Transfer to Own Account',
  },
  sknTransfer: {
    name: 'SKN',
    label: {
      transferToSkn: 'Transfer to LLG (Lalu Lintas Giro)/SKN (Sistem Kliring Nasional)',
      message: 'Message (Optional)',
      beneficiaryEmail: 'Beneficiary Email (Optional)',
      messagePlaceholder: 'Max 15 char',
    },
  },
  intrabank: {
    title: 'Transfer to Within Maybank',
    messagePlaceholder: 'Max 120 characters',
    message: 'Message (Optional)',
  },
  interbank: {
    title: 'Transfer to Real Time Transfer',
    noOptionsText: 'Beneficiary Bank is not found',
    message: 'Message (Optional)',
    messagePlaceholder: 'Max 40 char',
    beneficiaryBank: 'Beneficiary Bank',
    beneficiaryEmailOptional: 'Beneficiary Email (Optional)',
    beneficiaryEmail: 'Beneficiary Email',
  },
  rtgs: {
    name: 'Transfer to RTGS (Real Time Gross Settlement)',
    type: 'RTGS (Real Time Gross Settlement)',
  },
  virtualAccount: {
    name: 'Maybank Virtual Account',
    title: 'Transfer to Maybank Virtual Account',
  },
  eWallet: {
    name: 'E-Wallet',
    title: 'Transfer to E-Wallet',
    selectEWallet: 'Select E-Wallet',
    customerNumber: 'Customer Number',
    eWalletName: 'E-Wallet Name',
    adminFee: 'Admin Fee',
  },
  swift: {
    table: {
      swiftCode: 'SWIFT Code',
      bankName: 'Bank Name',
      firstAddress: 'Address 1',
      secondAddress: 'Address 2',
      thirdAddress: 'Address 3',
    },
    modal: {
      accountTitle: 'Account Number/IBAN',
      name: 'Transfer to SWIFT Foreign Currency Transfer',
      tooltip:
        'An IBAN number is an unique code used by some countries to identify a specific bank account number for the purpose of cross-border payments.',
      beneficiaryEmail: 'Beneficiary Email (Optional)',
      idNumber: 'ID Number',
      dateOfBirth: 'Date Of Birth',
      jpyBottomLabel: 'Please enter amount without decimal',
      limitTitle: 'Transaction Limit',
      limitMessage:
        'Transaction exceeding foreign currency limit. Please use M2U ID app for SWIFT foreign currency transfer with amount greater than equivalent USD 100,000.',
      notes:
        'Foreign exchange transaction limit is calculated based on an  exchange rate when transaction posted, which may differ from your transaction rate.',
    },
    placeholder: {
      bankName: 'Enter Bank Name',
      swiftCode: 'Enter SWIFT code',
      select: 'Please select',
      street: 'Street',
      other: 'Other',
      country: 'Country',
      message: 'Max 120 characters',
    },
    lld: {
      label: 'Foreign Exchange Activities',
      identityStatus: {
        label: 'Identical Status',
        no: 'Remitter is not identical with beneficiary',
        yes: 'Remitter is identical with beneficiary',
      },
      card: {
        description: 'Description',
        identicalStatus: 'Identical Status',
        transDescription: 'Transaction Description',
        remitterRelationship: 'Remitter Relationship',
      },
      citizenship: {
        label: 'Beneficiary Citizenship',
      },
      beneficiary: {
        label: 'Beneficiary Category',
        individual: 'Individual',
        financial: 'Financial Institution Non Bank',
        company: 'Company',
      },
      relationship: {
        label: 'Transactor Relationship',
        nonAffiliate: 'Non Afiliated',
        shareHolders: 'Share Holders',
        subsidiary: 'Subsidiary Company',
        group: 'Group',
      },
      purpose: {
        label: 'Transaction Purpose Category',
        production: 'Income/Expense',
        goods: 'Goods Transactions',
        services: 'Services Transactions',
        modal: 'Capital/Financial Transactions',
        unrequitted: 'Unrequitted Transfers',
      },
      description: {
        label: 'Description',
      },
      message: {
        label: 'Transaction Description (Optional)',
        placeholder: 'Max 120 characters',
      },
    },
  },
  errorMessage: {
    required: 'Please ensure you fill all information required for this transaction',
    accountLength: 'Account Number must be at least 10 digit',
    currency: 'Please select a currency',
    amount: 'Invalid amount.',
    accountNumber: 'Invalid account number.',
    customerNumber: 'Invalid customer number.',
    sameAccount: 'Please use option "Own Account" for fund transfer between your accounts.',
    sameEndDate: 'End Date must be later than Start Date.',
    endDate: 'Invalid End Date.',
    minAmount: 'Transfer amount must be greater than IDR {amount}',
    maxAmount: 'Transfer amount must be less than IDR {amount}',
    beneficiaryBankNotFound: 'Beneficiary Bank is not found',
    insufficientFund: 'Insufficient fund',
    beneficiaryAmount: 'Invalid Beneficiary Amount',
    beneficiaryName: 'Invalid Beneficiary Name',
    sourceOfFund: 'Invalid Source Of Fund',
    transactionPurpose: 'Invalid Transaction Purpose',
    beneficiaryType: 'Invalid Beneficiary Type',
    beneficiaryCitizenStatus: 'Invalid Beneficiary Citizen Status',
    startDate: 'Invalid Effective Date',
    frequency: 'Invalid Frequency',
    sknAmountLimit: 'Transaction amount should not be less or greater than allowed amount for LLG/SKN',
    beneficiaryEmail: 'Invalid Beneficiary Email',
    exceedBalance: "Your transaction amount can't be greater than your available balance",
    localCurrencyTransaction: 'Transaction can only be executed in local currency. Please select source of account in IDR',
    invalidIban: 'Invalid Account Number/IBAN',
    noEligibleAccount: 'You do not have an account eligible to perform this transaction',
  },
  tacTitle: 'Please confirm your instruction details.',
  tacTitleFc: 'Please confirm your new password.',
  tacDescription: 'To proceed with this instruction, please request and insert your <b>TAC</b> number.',
  resultTitle: 'Transaction {status}!',
  noOptionFound: 'No Option Found',
  changeAdminFee: 'Charged admin fee refers to prevailing fee and charges.',
};
