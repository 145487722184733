import ClientJS from 'clientjs';

const client = new window.ClientJS();

const userAgent = `${client.getBrowser()} ${client.getBrowserVersion()}, ${client.getOS()} ${client.getOSVersion()}`;
const fingerPrint = client.getFingerprint();
const timezone = client.getTimeZone();
const platform = client.getOS();
const browser = client.getBrowser();
const device = client.getBrowserVersion();

export { userAgent, fingerPrint, timezone, platform, browser, device };
