export const dashboard = {
  slogan: 'Welcome to Maybank2u',
  container: {
    title: 'Congratulations! You have successfully registered',
  },
  maintenance: 'We are currently undergoing system maintenance.',
  tryAgain: 'The system is temporary unavailable to process this request. Please try again later.',
  ccError: '{clickHere} to obtain a credit card facility from Maybank that suits your need and enjoy various attractive promos',
  header: {
    lastLogin: 'Your last login was on {timestamp}',
    topNavigation: {
      digitalWealth: 'My Portfolio 360',
      myAccount: 'My Account',
      transaction: 'Transaction',
      apply: 'Apply',
    },
  },
  sidebar: {
    topNavigation: {
      inbox: 'Inbox',
      settings: 'Settings',
      logout: 'Logout',
    },
    donation: {
      linkLabel: 'View total donation',
    },
    quickTransfer: {
      title: 'Quick Pay & Transfer',
    },
    footer: {
      tutorial: 'TUTORIAL',
      term: 'TERMS & CONDITION',
      privacy: 'PRIVACY POLICY',
      sec: 'SECURITY STATEMENT',
      fees: 'FEES & CHARGE',
    },
    link: {
      term: 'https://www.maybank.co.id/en/ebanking/syarat-dan-ketentuan-maybank2u',
      privacy: 'https://www.maybank.co.id/en/securityprivacypolicy#M2u',
      sec: 'https://www.maybank.co.id/en/securityprivacypolicy?categoryId={11C73F5C08214313B9985274B2894D52}',
      fees: 'https://www.maybank.co.id/en/ebanking/feeandcharge',
    },
  },
  casa: {
    label: 'Account',
    availableBalance: 'Available Balance',
    balanceIDR: 'Balance in IDR',
    equivalent: 'Equivalent {equivalentAmount}',
    currency: '{currencyAmount} Currencies',
    branch: 'Branch',
    initialBalance: 'Initial Balance',
    beginningBalance: 'Beginning Balance',
    holdBalance: 'Hold Balance',
    addAccount: 'Add Account',
    noAccount: 'You do not have any accounts with us.',
    balance: 'Balance',
    menu: {
      viewDetails: 'View Details',
      addAccount: 'Add Account',
      payment: 'Payment',
      transfer: 'Transfer',
      purchase: 'Purchase',
      test: 'Test EN',
    },
    details: {
      date: 'Date',
      description: 'Description',
      amount: 'Amount',
      today: 'Today',
      download: 'Download',
    },
    transactionHistory: {
      today: 'Today',
      thisMonth: 'This Month',
      previousMonth: 'Previous Month',
      noTransactionHistory: 'No Transaction History',
      allTransactionDetails: 'All Transaction',
      m2u: 'M2U Transaction',
    },
    notification: {
      title: 'Notification',
      content: 'Download Complete!',
    },
  },
  td: {
    leaveAlert: {
      title: 'Leave Time Deposit Page',
      description: 'Are you sure to leave this page? Click "Cancel" to stay here or click "OK" to leave this page.',
    },
    label: 'Time deposit',
    totalAmount: 'Total Amount',
    totalAccount: 'Total Account',
    maturityDate: 'Maturity Date: ',
    noAccount: 'You do not have any Time Deposit account with us.',
    menu: {
      viewDetail: 'View Detail',
      stopARO: 'Stop Automatic Rollover',
    },
    confirmTac: {
      akad: 'Akad',
      amountLabel: 'Amount',
      depositAccountNumber: 'Deposit Account Number',
      from: 'Transfer from',
      initialDepositTitle: 'Initial Deposit',
      instructionAtMaturity: 'Instruction at Maturity',
      interestRate: 'Interest Rate',
      interestRateIB: 'Indicative Rate of Return (%p.a)',
      referenceNo: 'Reference Number',
      resultTitleFail: 'Transaction Failed!',
      resultTitleSuccess: 'Transaction Success!',
      successNotif: 'New Deposit Has Been Successfully Added',
      tacTitle: 'Please confirm your instruction details.',
      tacDescription: 'To proceed with this instruction, please request and insert your <b>TAC</b> number.',
      term: 'Term',
      zakat: 'Zakat (2.5% of Return)',
    },
    products: {
      description: 'Open Time Deposit Now and get competitive rate up to 5.00%',
      descriptionIB: 'Open Online Time Deposit iB and get indicative profit sharing ratio up to 5.00%',
    },
    errorMessage: {
      invalidPromoCode: 'Invalid Promo Code',
      invalidReferralCode: 'Invalid Referral Code',
      maxAmount: 'The value you entered is exceeding the maximum limit of {amount}',
      minAmount: 'The value you entered is less than the minimum limit of {amount}',
      required: 'This field cannot be empty',
    },
    features: {
      accountDetailNotesTitle: 'Notes:',
      accountDetailNotesContent:
        '<ul style="list-style-type:disc;margin:0;padding:0;"><li>Minimum deposit placement is IDR 10,000,000 or USD 2,000.</li><li>Placement / Mature of deposits on Saturdays, Sundays or national holidays will be processed on the following business day.</li><li>Termination of Deposits with Principal Roll Over and Principal + Interest Roll Over instructions can only be done via Maybank2U using Stop Auto Roll Over feature on Deposits detail, no later than D-2 working days before the maturity date. If Stop Auto Roll Over done in less than D-2 working days than maturity date will be effective on the next period of maturity date. Principal and Interest will be transferred to source account on maturity date.</li><li>Please ensure that Instruction at Maturity changed to Terminated if using Stop Auto Roll Over feature</li><li>Time Deposit maturity can only be done on maturity date.</li></ul>',
      accountDetailNotesContentIB:
        '<ul style="list-style-type:disc;margin:0;padding:0;"><li>Minimum deposit placement is IDR 10,000,000 or USD 2,000.</li><li>Placement / Mature of Time Deposit iB on Saturdays, Sundays or national holidays will be processed on the following business day.</li><li>Termination of Time Deposit iB with Principal Roll Over and Principal + Interest Roll Over instructions can only be done via Maybank2U using Stop Auto Roll Over feature on Deposits detail, no later than D-2 working days before the maturity date. If Stop Auto Roll Over done in less than D-2 working days than maturity date will be effective on the next period of maturity date. Principal and Interest will be transferred to source account on maturity date.</li><li>Please ensure that Instruction at Maturity changed to Terminated if using Stop Auto Roll Over feature</li><li>Time Deposit iB maturity can only be done on maturity date.</li></ul>',
      accountDetailTitle: 'Account Details',
      head: '<b>Maybank Time Deposit</b>',
      headIB: '<b>Maybank Time Deposit iB:</b><br/>Time deposit with Mudharabah contract',
      featureTitle: '<b>Feature</b>',
      form: {
        sof: 'From Account',
        term: 'Term',
        amount: 'Amount',
        instructionAtMaturity: 'Instruction at Maturity',
        instructionAtMaturityValue1: 'Principal Roll Over',
        instructionAtMaturityValue2: 'Principal + Interest Roll Over',
        instructionAtMaturityValue3: 'Terminated',
        akad: 'Akad',
        akadValue1: 'Mudharabah',
        checkboxReferral: 'Please tick here to enter Referral Code',
        referralAndPromoCode: 'Referral & Promo Code',
        promoCode: 'Promo Code (optional)',
        referralCode: 'Referral Code (optional)',
        zakat: 'Zakat (2.5% of Return)',
      },
      benefitsTitle: '<b>Benefits:</b>',
      benefitsContent: '<li>Competitive Interest Rate.</li><li>Available in IDR & USD.</li><li>Available Roll Over facility.</li>',
      benefitsContentIB:
        '<li>Competitive Profit Sharing.</li><li>Available in IDR & USD.</li><li>Available Roll Over facility.</li><li>Option to automatically pay zakat from the return obtained.</li>',
      checklistLabel:
        '<b>By this,</b><ol style="margin: 0;padding-left:1em;"><li style="font-weight:normal;">I hereby that I am no other country or US tax payer OR in term that I have submitted the self-statement form on FATCA/CRS beforehand to the Bank and there is no changes regarding that statement. I will inform the Bank in writing at least 90 days calendar if there is changes on data or information including but not limited to my tax status or other mandatory information which cause my statement regarding FATCA/CRS to become invalid or not complete.</li><li style="font-weight:normal;">I have read and understand the product features.</li><li style="font-weight:normal;">I certify that I have read and agree to the Terms and Conditions applied.</li></ol>',
      checklistLabelIB:
        '<b>By this,</b><ol style="margin: 0;padding-left:1em;"><li style="font-weight:normal;">I hereby that I am no other country or US tax payer OR in term that I have submitted the self-statement form on FATCA/CRS beforehand to the Bank and there is no changes regarding that statement. I will inform the Bank in writing at least 90 days calendar if there is changes on data or information including but not limited to my tax status or other mandatory information which cause my statement regarding FATCA/CRS to become invalid or not complete.</li><li style="font-weight:normal;">I have read and understand the product features.</li><li style="font-weight:normal;">I certify that I have read and agree to the Terms and Conditions and the Akad applied</li><li style="font-weight:normal;">I have understood that the Terms and Conditions on this account opening is inseparable with the Akad Mudharabah of Deposito iB</li></ol>',
      downloadTermandCondition: 'Download Terms & Conditions',
      downloadAkadMudharabah: 'Download Akad Mudharabah',
      interestRateTitle: '<b>Interest Rate (%p.a)</b>',
      interestRateTitleIB: '<b>Indicative Rate of Return (%p.a)</b>',
      interestRateTableHeaders: {
        placement: 'Nominal Placement',
        value1: '1 Month',
        value2: '3 Month',
        value3: '6 Month',
        value4: '12 Month',
      },
      minimumPlacementTitle: '<b>Minimum Deposit</b>',
      minimumPlacementContent: '<li>IDR: 10,000,000</li><li>USD: 2,000</li>',
      noInterestRate: 'No Intereset Rate.',
      noInterestRateIB: 'No Indicative Rate of Return.',
      productDetailTitle: 'Product Details',
      tncTitle: '<b>Terms & Conditions:</b>',
      tncTitle2: '<b>Terms & Conditions</b>',
      tncContent:
        '<ul style="display: inline-block; margin-top: 0px; margin-left: 15px; padding: 0px;"><li>Maybank Time Deposit can only be withdrawn at maturity date.</li><li>If the Customer chooses an automatic roll over of time Maybank Time Deposit, then the termination of the roll-over could only be done online via M2U ID App/M2U ID Web, then the principal amount and interest will be transferred to the source account on the maturity date.</li></ul>',
      tncContentIB:
        '<ul style="display: inline-block; margin-top: 0px; margin-left: 15px; padding: 0px;"><li>Maybank Time Deposit iB can only be withdrawn at maturity date.</li><li>If the Customer chooses an automatic roll over of time Maybank Time Deposit iB, then the termination of the roll-over could only be done online via M2U ID App/M2U ID Web, then the principal amount and interest will be transferred to the source account on the maturity date.</li><li>Ratio tables applies to Opening Maybank Time Deposit iB via M2U ID App/M2U ID Web.</li></ul>',
      placementPeriodsTitle: '<b>Placement Periods</b>',
      placementPeriodsContent: 'Start from 1,3,6 & 12 month',
    },
    details: {
      balance: 'Balance',
      balanceIDR: 'Balance in IDR',
      accountNo: 'Acc Number',
      principalAmount: 'Principal Amount',
      holdAmount: 'Hold Amount',
      interestRate: 'Interest Rate',
      indicativeEquivalentRate: 'Indicative Equivalent Rate',
      indicativeRateofReturn: 'Indicative Rate of Return',
      term: 'Term',
      placementDate: 'Placement Date',
      maturityDate: 'Maturity Date',
      nextPaymentDate: 'Next Interest payment date',
      nextIndicativeEquivalentRatePaymentDate: 'Next Indicative Equivalent Rate Payment Date',
      uponMaturity: 'Instruction Upon maturity',
      accruedInterest: 'Accrued Interest Rate',
      accruedIndicativeEquivalentRate: 'Accrued Indicative Equivalent Rate',
      branch: 'Branch',
      transactionDate: 'Transaction Date',
      refId: 'Ref ID',
      profitSharingRatio: 'Profit Sharing Ratio',
    },
    placeholder: {
      pleaseSelect: 'Please Select',
    },
    stopAro: {
      tacTitle: 'Please confirm your instruction details.',
      tacDescription: 'To proceed with this instruction, please request and insert your <b>TAC</b> number.',
      transaction: {
        successful: 'Your request is successful!',
        failed: 'Your request is failed!',
      },
      notice: 'Request to stop maturity instruction for "Automatic Rollover" will be effective 2 days after it is submitted to the Bank.',
    },
  },
  cc: {
    label: 'Credit card',
    billingCycle: 'Billing Cycle',
    creditLimit: 'Credit Limit',
    availableCredit: 'Available Credit',
    currentPayment: 'Current Payment',
    ccHolderName: 'Card Holder Name',
    expDate: 'Expiry Date',
    cashAdvancedLimit: 'Cash Advanced Limit',
    dailyCashAdvancedLimit: 'Daily Cash Advanced Limit',
    currentBalance: 'Current Balance',
    outstandingAuthItem: 'Outstanding Authorization Item',
    outstandingAuthAmount: 'Outstanding Authorization Amount',
    availableCashAdvanced: 'Available Cash Advanced As Of {date}',
    dailyCashAdvanced: 'Daily Cash Advanced As Of {date}',
    noAccount: 'to obtain a credit card facility from Maybank that suits your need and enjoy various attractive promos',
    billingAmount: 'Full Payment',
    minAmount: 'Minimum Payment',
    otherAmount: 'Any Amount',
    currentUsage: 'Current Usage',
    due: 'due',
    menu: {
      viewDetail: 'View Detail',
      payCard: 'Pay Card',
      blockCard: 'Block Card',
    },
    details: {
      date: 'Date',
      description: 'Description',
      amount: 'Amount',
    },
    transactionHistory: {
      recentTransaction: 'Recent Transaction',
      lastStatement: 'Last Statement',
      previousStatement: 'Previous Statement',
      noTransactionHistory: 'No Transaction History',
      allTransactionHistory: 'All Transaction History',
    },
    link: {
      clickHere: 'Click here',
      ccReg: 'https://www.maybank.co.id/creditcard',
    },
  },
  loan: {
    label: 'Loan/Financing',
    totalLoan: 'Total Remaining Loan/Financing',
    accountType: 'Account Type',
    currency: 'Currency',
    noAccount: 'to obtain a financing facility from Maybank that suits your need and enjoy various attractive promos',
    branch: 'Branch',
    marginRate: 'Current Margin Rate',
    rollOver: 'Next Rollover',
    noteNumber: 'Note Number',
    delayPeriod: 'Delay Period',
    principalDueAmount: 'Principal Amount Due',
    principalDueDate: 'Principal Due Date',
    productType: 'Product Type',
    monthlyInstallment: 'Monthly Installment',
    installmentOverdue: 'Late Installment Amount',
    defaultInterest: 'Default Late Interest',
    remainingOutstanding: 'Total Principal Loan/Financing',
    loanPeriod: 'Loan/Financing Period',
    lastInstallmentDate: 'Last Installment Date',
    lateChargeFee: 'Late Charge',
    latePaymentCharge: 'Late Payment Charge',
    currentInterestRate: 'Interest Rate',
    indicativeRateofReturn: 'Indicative Rate of Return',
    nextPaymentDue: 'Next Payment Due Date: ',
    noPaymentHistory: 'No Payment History',
    maturity: 'Maturity Date',
    menu: {
      viewDetail: 'View Detail',
    },
    details: {
      maturity: 'Maturity Date',
      currentBalance: 'Current margin balance',
      totalBilled: 'Total principal billed',
      outstanding: 'Loan/Financing outstanding',
      payoffAmount: 'Loan/Financing Payoff Amount as of today',
      tenor: 'Loan/Financing tenor',
    },
  },
  wealth: {
    label: 'Investment',
    product: 'Products',
    totalValue: 'Total Value',
    totalProduct: 'Total Product',
    gcif: 'GCIF',
    sid: 'SID',
    mutualFund: 'Mutual Fund',
    bonds: 'Bonds',
    noAccount: 'You do not have any Wealth Products with us.',
    details: {
      productName: 'Product name',
      policyHolderName: 'Policy holder name',
      balanceUnit: 'Balance Unit',
      unit: 'Unit',
      navDate: 'NAV date',
      fundName: 'Fund name',
      fundManager: 'Fund manager',
      accountNo: 'Account number',
      totalUnit: 'Total unit',
      totalValue: 'Total Value',
      nav: 'NAV',
      productType: 'Product Type',
      pendingSwitching: 'Pending Switching',
      growthFund: 'Growth Fund',
      balance: 'Balance',
      balance2: 'Balance',
      coupon: 'Coupon',
      maturityDate: 'Maturity Date',
      tenor: 'Tenor',
      policyNumber: 'Policy Number',
      currency: 'Currency',
      outstandingUnit: 'Outstanding Unit',
      navAsOf: 'NAV as of {date}',
      pendingSubscription: 'Pending Subscription',
      pendingRedemption: 'Pending Redemption',
      productCode: 'Product Code',
      couponPeriod: 'Coupon Period',
      couponDate: 'Coupon Date',
      startDateEarlyRedemption: 'Start Date Early Redemption',
      endDateEarlyRedemption: 'End Date Early Redemption',
      settlementDate: 'Settlement Date',
      gainLossPotency: 'Potential Gain/Loss',
      payeeName: 'Payee Name',
      policyHolder: 'Policy Holder',
    },
    history: {
      nav: 'NAV',
      date: 'DATE',
      unit: 'UNIT',
      price: 'PRICE',
      amount: 'AMOUNT',
      faceValue: 'FACE VALUE',
      description: 'DESCRIPTION',
      productName: 'PRODUCT NAME',
      transactionType: 'TRANSACTION TYPE',
      day30: '30 Days',
      day60: '60 Days',
      day90: '90 Days',
      noTransactionHistory: 'No Transaction History',
    },
    disclaimer: {
      exchangeRate:
        'Foreign exchange used to calculate your assets in IDR is an indicative rate and it can change at any time without prior notice',
      detail:
        "Mutual Funds, Bond Retail and/or Bancassurance Outstanding Report is an additional service provided by Maybank, and it is not replacing any document issued by authorized third parties such as Custodian Banks or Maybank's Product Partners. In the event of any discrepancy between the information/value of the report with the documents issued by authorized third parties, Customer should refer the documents issued by authorized third parties as valid documents.",
      feedback:
        'For your suggestions and feedback, please contact the service 24 hours Maybank Customer Care on 1500611 directly from your mobile phone or email <a href="mailto:customercare@maybank.co.id">customercare@maybank.co.id</a>',
    },
  },
  coachmark: {
    title: 'Tutorial',
    description: 'Welcome to M2U ID Web! Let us guide you to navigate our brand new M2U',
    checklist: 'Remember my decision',
  },
  sessionExpired: {
    title: 'Session Timeout',
    description: 'Your Session will expire in {counting}. {br} Click OK to stay Login or click CANCEL to logout.',
  },
};
